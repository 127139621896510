import React from "react";
import Layout from "../../layouts/LayoutIframe";
import Seo from "../../components/Seo";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import QuestionnaireContainer from "../../containers/common/QuestionnaireContainer";

const WAQuestionnaire = () => {
  return (
    <Layout withTagManager={false}>
      <Seo
        title=""
        description=""
        page=""
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
      />
      <QuestionnaireContainer isWaMode />
    </Layout>
  );
};

export default WAQuestionnaire;
