import React from "react";
import Content from "./Content";
import FinalContent from "./FinalContent";
import "./index.scss";

const Questionnaire = ({ currentQuestion, questionsAnswer }) => {
  return (
    <div className="GC-Q">
      <Content
        currentQuestion={currentQuestion}
        questionsAnswer={questionsAnswer}
      />
      <FinalContent />
    </div>
  );
};

export default Questionnaire;
