import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Header = (props) => {
  const { title, subtitle, currentQuestion } = props;

  if (currentQuestion !== 0) return null;

  return (
    <div>
      <div className="GC-Q-Content-Header__title">{title}</div>
      <div className="GC-Q-Content-Header__subtitle">{subtitle}</div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  currentQuestion: PropTypes.number.isRequired,
};

export default Header;
