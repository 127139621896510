import React, { useEffect, useState } from "react";
import { PageContext } from "../../store/context/page-context";
import QUESTIONNAIRE_CONTENT from "../../components/common/Questionnaire/index.content";
import Questionnaire from "../../components/common/Questionnaire";

const QuestionnaireContainer = ({ slug, isOpen, handlerOpenQuestionnaire }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionsAnswer, setQuestionsAnswer] = useState({});
  const [isFinal, setIsFinal] = useState(false);
  const [isResellingCourse, setIsResellingCourse] = useState(false);

  const {
    questions,
    finalContent: { textResellingCourse, textCourse, title },
    path,
  } = QUESTIONNAIRE_CONTENT;
  const finalContentText = isResellingCourse ? textResellingCourse : textCourse;

  const handleChangeAnswer = (index) => {
    const newQuestionsAnswer = {
      ...questionsAnswer,
      [currentQuestion]: index,
    };
    setQuestionsAnswer(newQuestionsAnswer);
    nextQuestion(newQuestionsAnswer);
  };

  const handleActionFinalContent = (isButton) => {
    const goTo = isResellingCourse ? "resellingCourse" : "course";
    const isRedirect = slug !== path[goTo];
    localStorage.setItem("questionnaire", "closed");
    handlerOpenQuestionnaire(false);
    isButton && isRedirect && window.open(path[goTo], "_self");
  };

  const handleSkipButton = () => nextQuestion(questionsAnswer);

  const nextQuestion = (currentQuestionsAnswer) => {
    if (currentQuestion === questions.length - 1) {
      const courseAnswers = Object.entries(currentQuestionsAnswer).reduce(
        (res, [key, value]) => {
          const answer = checkIsCourse(+key, value) ? 1 : 0;
          return res + answer;
        },
        0
      );
      setIsResellingCourse(courseAnswers < 3);
      setIsFinal(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const checkIsCourse = (question, answer) => {
    return (
      (question === 0 && answer === 1) ||
      (question === 1 && answer === 0) ||
      (question === 2 && answer === 0) ||
      (question === 3 && answer === 1)
    );
  };

  useEffect(() => {
    const htmlBlock = document.getElementsByTagName("html")[0];
    htmlBlock.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <PageContext.Provider
      value={{
        isFinal,
        content: QUESTIONNAIRE_CONTENT,
        handleSkipButton,
        finalContentText,
        finalContentTitle: title,
        setCurrentQuestion,
        handleChangeAnswer,
        handleActionFinalContent,
      }}
    >
      <Questionnaire
        currentQuestion={currentQuestion}
        questionsAnswer={questionsAnswer}
      />
    </PageContext.Provider>
  );
};

export default QuestionnaireContainer;
