const QUESTIONNAIRE_CONTENT = {
  title:
    "Welcome to AMZScout's Step-by-Step Courses to help you start selling on Amazon.",
  subtitle:
    "Please share how you plan to start your Amazon business so that we can offer you the most appropriate course to steer you towards success.",
  questions: [
    {
      title: "What is your starting budget for your Amazon business?",
      answers: [
        "Less than $2,000",
        "More than $2,000",
        "I have not decided yet, or I haven't given it much thought",
      ],
    },
    {
      title:
        "On Amazon, you can sell products from already established brands, including large ones like Adidas, or smaller niche brands. Alternatively, you can start your own brand of products. What is your plan?",
      answers: [
        "To sell your own brand new product",
        "To sell products from existing brands without launching your own brand",
        "I have not decided yet",
      ],
    },
    {
      title:
        "Are you interested in selling products with high profit margins with a higher risk that the product may not sell well, or would you rather have lower margins and more predictable product sales?",
      answers: [
        "I want high margins and am willing to try new products",
        "I prefer more predictable product sales with lower margins",
        "I have not decided yet",
      ],
    },
    {
      title: "What is most important to you right now?",
      answers: [
        "I want to quickly get a sense of what it's like to sell on Amazon, make my first sales fast, and gain experience",
        "I want to start a business that I can scale up and primarily put on autopilot. To do that, I am ready to put in the effort now, and wait a few months before seeing my first sales.",
      ],
    },
  ],
  finalContent: {
    title: "Thank you for your responses!",
    textCourse: [
      'The “Amazon Private Label Course" is the course that will teach you how to sell on Amazon using methods best suited to your needs.',
      'Later, if you want to learn alternative ways to sell on Amazon, we recommend you watch the "Amazon Dropshipping, Arbitrage, and Wholesale Course”.',
    ],
    textResellingCourse: [
      'The “Amazon Dropshipping, Arbitrage, and Wholesale Course" is the course that will teach you how to sell on Amazon using methods best suited to your needs.',
      'Later, if you want to learn alternative ways to sell on Amazon, we recommend you watch the "Amazon Private Label Course”.',
    ],
  },
  path: {
    course: "amazon-private-label-course",
    resellingCourse: "amazon-seller-training-courses",
  }
}

export default QUESTIONNAIRE_CONTENT
