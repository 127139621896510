import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../../../store/context/page-context";
import Pagination from "./Pagination";
import "./index.scss";

const Bottom = ({ currentQuestion, amountQuestions }) => {
  const { handleSkipButton } = useContext(PageContext);

  return (
    <div className="GC-Q-Content-Bottom">
      <Pagination
        currentQuestion={currentQuestion}
        amountQuestions={amountQuestions}
      />
      <div className="GC-Q-Content-Bottom__skip" onClick={handleSkipButton}>
        Skip
      </div>
    </div>
  );
};

Bottom.propTypes = {
  currentQuestion: PropTypes.number.isRequired,
  amountQuestions: PropTypes.number.isRequired,
};

export default Bottom;
