import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../../../store/context/page-context";
import { getTrueClasses } from "../../../../../helpers";
import Answer from "./Answer";
import "./index.scss";

const Question = (props) => {
  const { title, answers, questionsAnswer, index } = props;
  const { handleChangeAnswer } = useContext(PageContext);

  const classes = getTrueClasses(
    "GC-Q-Content-Question",
    `GC-Q-Content-Question-${index}`
  );
  return (
    <div className={classes}>
      <div className="GC-Q-Content-Question__title">{title}</div>
      <div>
        {answers.map((text, i) => (
          <Answer
            text={text}
            key={text}
            active={questionsAnswer === i}
            handleChangeAnswer={() => handleChangeAnswer(i)}
          />
        ))}
      </div>
    </div>
  );
};

Question.propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  questionsAnswer: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  index: PropTypes.number.isRequired,
};

export default Question;
