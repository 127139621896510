import React, { useContext } from "react";
import CloseSvg from "../../../../assets/images/vector/components/common/closeInit.svg";
import { PageContext } from "../../../../store/context/page-context";
import "./index.scss";

const FinalContent = () => {
  const {
    handleActionFinalContent,
    isFinal,
    finalContentText,
    finalContentTitle,
  } = useContext(PageContext);

  if (!isFinal) {
    return null;
  }

  return (
    <div className="GC-Q-FinalContent">
      <div className="GC-Q-FinalContent__title">{finalContentTitle}</div>
      <div className="GC-Q-FinalContent__text">
        {finalContentText.map((item) => (
          <div key={item}>{item}</div>
        ))}
      </div>
      <div
        className="GC-Q-FinalContent__button"
        onClick={() => handleActionFinalContent(true)}
      >
        WATCH COURSE
      </div>
      <img src={CloseSvg} alt="X" onClick={handleActionFinalContent} />
    </div>
  );
};

export default FinalContent;
