import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../../helpers";
import "./index.scss";

const Answer = ({ text, active, handleChangeAnswer }) => {
  const classes = getTrueClasses(
    "GC-Q-Content-Question-Answer",
    active && "GC-Q-Content-Question-Answer_active"
  );

  return (
    <div className={classes} onClick={handleChangeAnswer}>
      <div className="GC-Q-Content-Question-Answer__point" />
      <div className="GC-Q-Content-Question-Answer__text">{text}</div>
    </div>
  );
};

Answer.propTypes = {
  text: PropTypes.string.isRequired,
  handleChangeAnswer: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default Answer;
