import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../../../../store/context/page-context";
import { getTrueClasses } from "../../../../../../helpers";
import "./index.scss";

const Pagination = ({ currentQuestion, amountQuestions }) => {
  const { setCurrentQuestion } = useContext(PageContext);

  const questionsNumbers = useMemo(() => {
    return new Array(amountQuestions).fill(1).map((num, index) => index + 1);
  }, [amountQuestions]);

  const getClasses = (i) =>
    getTrueClasses(
      "GC-Q-Content-Bottom-Pagination__item",
      i <= currentQuestion && "GC-Q-Content-Bottom-Pagination__item-active"
    );

  return (
    <div className="GC-Q-Content-Bottom-Pagination">
      {questionsNumbers.map((num, i) => (
        <div
          key={num}
          className={getClasses(i)}
          onClick={() => setCurrentQuestion(i)}
        >
          <div />
          <div>{num}</div>
        </div>
      ))}
    </div>
  );
};

Pagination.propTypes = {
  currentQuestion: PropTypes.number.isRequired,
  amountQuestions: PropTypes.number.isRequired,
};

export default Pagination;
