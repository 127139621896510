import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../../../store/context/page-context";
import Question from "./Question";
import Header from "./Header";
import Bottom from "./Bottom";
import "./index.scss";

const Content = ({ currentQuestion, questionsAnswer }) => {
  const {
    isFinal,
    content: { title, subtitle, questions },
  } = useContext(PageContext);

  if (isFinal) {
    return null;
  }

  return (
    <div className="GC-Q-Content">
      <Header
        title={title}
        subtitle={subtitle}
        currentQuestion={currentQuestion}
      />
      <Question
        title={questions[currentQuestion].title}
        answers={questions[currentQuestion].answers}
        questionsAnswer={questionsAnswer?.[currentQuestion]}
        index={currentQuestion}
      />
      <Bottom
        currentQuestion={currentQuestion}
        amountQuestions={questions.length}
      />
    </div>
  );
};

Content.propTypes = {
  currentQuestion: PropTypes.number.isRequired,
  questionsAnswer: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default Content;
